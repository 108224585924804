.fieldBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
}

.inputBox {
  outline: none;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%);
  border-radius: 8px;
  width: 210px;
  height: 50px;
  font-family: "Nunito-regular-400";
  font-size: 14px;
  color: #000;
  padding: 0px 15px;
  cursor: pointer;
  background-color: #ffffff !important;
}

.inputBox:focus,
.inputBox:hover,
.inputBox:active,
.authInputBox:focus,
.authInputBox:hover {
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%);
}

.itemsPagetext {
  margin: 2px 7px 7px;
  text-align: end;
  font-size: 14px;
  font-family: "Nunito-regular-400";
}

.itemsPagetext span {
  font-weight: 900;
}

.filterselectinput {
  outline: none;
  background: #ffffff !important;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%);
  border-radius: 8px;
  width: 210px;
  height: 51px;
  font-family: "Nunitos";
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  appearance: none;
}

.filterselectinput:hover > .ant-select-selector,
.filterselectinput:focus > .ant-select-selector,
.filterselectinput:active > .ant-select-selector {
  border: 0.5px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%) !important;
}

.inlinefield {
  border-radius: 10px;
  box-shadow: none;
  background: #b2e0ff !important;
  font-size: 14px;
  border: 1px solid #a3a0ff8f;
  max-width: 110px;
  height: 25px;
  outline: none !important;
  padding: 8px 10px;
}

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  padding: 5px 2%;
}

.checkTitle {
  font-family: var(Nunito-regular-400);
  color: #ffffff;
  font-size: 9;
  text-align: left;
}
.TimePickerStyle {
  width: 100%;
}
.timeField {
  outline: none;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  width: 100px;
  height: 51px;
  font-family: "Nunitos";
  font-size: 14px;
  font-weight: 500[];
  color: rgba(0, 0, 0, 0.7);
  appearance: none;
  min-width: 100px;
  max-width: 150px;
}
