@font-face {
  font-family: "Nunito";
  src: url(../../Assets/Fonts/Nunito-Bold.ttf);
}

@font-face {
  font-family: "Nunitos";
  src: url(../../Assets/Fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: "Nunito-bold-700";
  src: url(../../Assets/Fonts/Nunito-Bold.ttf);
}

@font-face {
  font-family: "Nunito-semibold-600";
  src: url(../../Assets/Fonts/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: "Nunito-regular-400";
  src: url(../../Assets/Fonts/Nunito-Regular.ttf);
}

.container-div {
  display: flex;
  align-items: center;
  font-family: "poppins";
  font-size: 15px;
  width: 1300px;
  margin: auto;
  font-weight: 600;
  justify-content: space-between;
  padding: 0;
}

.body-div {
  padding: 0px 0px;
}

.container-body {
  height: fit-content;
  width: 70%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 60px;
}

.router-pages {
  margin: 8px 20px;
}

/* .auth-input
{
    width: 488px;
    padding: 7px;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #a6aac6de;
    box-shadow: 0px 0px 8px rgb(76 87 125 / 10%);
    border-radius: 20px;
    margin: 8px 0 0 0;
    font-size: 15px;
    text-transform: capitalize;
} */

.dash-label {
  color: white;
  font-size: 21px;
  font-weight: 400;
}

.checkmark {
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 400px;
}

.map {
  width: 100% !important;
  height: 400px !important;
}

.side-bar {
  background: linear-gradient(167.76deg,
      #9cd4ec -32.26%,
      #2288b8 36.24%,
      #06567c 109.17%);
  width: 200px !important;
  height: 100%;
  position: fixed;
  position: fixed;
  border-radius: 0px 26px 0px 0px;
  overflow: auto;
}

/* Custom scrollbar for WebKit browsers */
.side-bar::-webkit-scrollbar {
  width: 10px;
  /* Width of the vertical scrollbar */
  height: 12px;
  /* Height of the horizontal scrollbar */
}

/* Background of the scrollbar track */
.side-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light gray */
  border-radius: 10px;
  /* Rounded corners */
}

/* Thumb (the draggable part of the scrollbar) */
.side-bar::-webkit-scrollbar-thumb {
  background: #888;
  /* Medium gray */
  border-radius: 10px;
  /* Rounded corners */
}

/* Thumb on hover */
.side-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker gray */
}

.dashbord-pages {
  display: flex;
  align-items: center;
  gap: 12px;
  /* margin: 0px auto; */
  text-transform: capitalize;
  height: 50px;
  border-radius: 8px;
  padding: 2px 10px;
  text-decoration: none;
  cursor: pointer;
}

.page-name {
  color: white;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
  font-family: "Nunito-regular-400";
  text-align: left;
}

.icons {
  background-color: white;
  border-radius: 7px;
  width: 27px;
  margin-right: 13px;
  font-size: 27px;
  color: #288ebf;
  margin: 0px !important;
}

.production_icons {
  background-color: white;
  border-radius: 7px;
  width: 27px;
  margin-right: 13px;
  font-size: 27px;
  color: #288ebf;
  margin: 0px !important;
}

.nameicon {
  background-color: white;
  border-radius: 7px;
  width: 27px;
  /* margin-right: -4px; */
  font-size: 45px;
  color: #288ebf;
  margin-top: -3px !important;
}

.dashbord-pages:hover {
  background-color: #2185b5 !important;
  padding: 2px;
  border-radius: 8px;
  padding: 2px 10px;
  text-decoration: none;
}

.user {
  padding: 4px;
  width: 27px;
}

.home {
  padding: 6px;
  width: 27px;
}

.lout {
  padding: 8px;
  width: 31px;
}

.dashbord {
  display: flex;
  flex-direction: row;
  font-family: "Nunito";
}

.top-bar {
  display: flex;
  flex-direction: column;
  /* width: calc(100%-300px) !important; */
  /* margin-left: 300px; */
  width: 100%;
}

/* @media (min-width: 1000px) {
  .top-bar {
    display: none;
  }
} */
.nav-container {
  margin: 10px auto;
  text-align: center;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  padding: 10px 10px 10px 24px;
}

.productionnav {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 10px; */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 10px;
  /* max-width: 966px;
  margin-left: 739px; */
}

.productionnav .top-nav {
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
}

@media (max-width: 999px) {
  .productionnav {
    display: none;
  }
}

.login-name {
  color: #014c70 !important;
  font-size: 25px !important;
  font-weight: 600;
  font-family: "Nunitos";
  background-color: white !important;
  border: none !important;
  outline: none !important;
  margin-bottom: 0px !important;
  padding: 1px 6px !important;
  cursor: default;
}

/* .login-name:hover {
  color: #888888!important;
  font-size: 19px;
  font-weight: 500;
  font-family: "Nunitos";
  border: none!important;
  outline: none!important;
  background-color: white;
} */
.login-name:focus {
  color: #888888 !important;
  font-size: 19px;
  font-weight: 500;
  font-family: "Nunitos";
  background-color: white;
}

.page-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: white !important;
  position: sticky;
  top: 0;
  z-index: 999;
}

.app-logo {
  width: 148px;
  height: 100px;
}

.controller-list {
  display: flex;
}

.motor {
  outline: none;
  border: none;
  color: white;
  font-family: "Nunito";
  background: #06567b;
  border-radius: 8px;
  padding: 12px 21px;
  float: right;
  font-weight: 600;
  font-size: 16px;
  border: 2px solid #06567b;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
}

.light {
  outline: none;
  border: none;
  color: rgb(48, 44, 44);
  font-family: "Nunito";
  /* background: rgba(6, 86, 123, 0.03); */
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 12px 21px;
  float: right;
  font-weight: 600;
  font-size: 16px;
}

.controllers {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  position: fixed;
  bottom: 15px;
  right: 24px;
  z-index: 9999;
}

.Dates {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  align-items: flex-start;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 7px;
}

.navimg {
  width: 124px;
  height: 41px;
  cursor: pointer;
}

/* .layout-btn {
  color: black;
  background-color: yellow;
} */
.from-to-date {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.date-label {
  font-size: 18px;
  font-weight: 900;
}

.layout-btn {
  background: #fbc225;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  font-size: 16px;
  color: white;
  padding: 1px 20px;
  font-weight: 700;
  height: 43px;
  font-family: "Nunito-regular-400";
  height: 40px;
}

.TimeFormatContainer {
  display: flex;
  gap: 10px;
}

.total-datas {
  font-size: 18px;
  font-weight: 900;
  font-family: "Nunito";
  margin-bottom: 0 !important;
  margin-top: revert !important;
}

.total_datas {
  font-size: 18px;
  font-weight: 900;
  font-family: "Nunito";
  margin: 0 !important;
  cursor: default;
}

.map-datas {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  /* margin: 8px; */
  align-items: center;
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 250px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 250px;
}

.subpage-name {
  color: gray !important;
  font-family: "Nunito";
}

.subpage-name:focus {
  color: white !important;
  font-family: "Nunito";
  background-color: #06567b !important;
}

.subpage-name:hover {
  color: white !important;
  font-family: "Nunito";
  background-color: #2185b5 !important;
}

.dropdn {
  display: flex;
  flex-direction: column;
  gap: 1px 1px;
  /* justify-content: space-evenly; */
  align-items: flex-start;
  padding: 2px 0px 0px 56px;
}

.droppage {
  margin-bottom: 0px !important;
}

.droppage {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 6px auto;
  text-transform: capitalize;
  height: 46px;
  border-radius: 8px;
  padding: 2px 10px;
  text-decoration: none;
  color: white;
}

.droppage:hover {
  background-color: #2185b5 !important;
  padding: 2px;
  border-radius: 8px;
  padding: 2px 10px;
  text-decoration: none;
}

/* .layout-btn:hover {
  color: #ffff !important;
} */
.light:hover {
  border-color: #06567b;
  color: #06567b;
}

/* .light:hover .light-img {
  color: black;
} */
#marker-example {
  margin-bottom: 75px;
}

/* .motor:hover {
  background: #ffff;
  color: #06567b !important;
  border: 2px solid #06567b;
} */
/* .motor:hover .darklight-img {
  color: #06567b !important;
} */

.notificationIcon {
  width: 24px;
  margin-right: 7px;
  cursor: pointer;
}

.responsiveToggleContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.webtext {
  outline: none;
  background: #ffffff;
  border: 0.5px solid rgb(0 0 0 / 3%);
  box-shadow: 0px 3px 5px rgb(0 0 0 / 25%);
  border-radius: 8px;
  width: 250px;
  padding: 10px 10px;
  font-family: "Nunitos";
  font-size: 17px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  appearance: none;
}

.webtextflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.production_webtextflex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53%;
}

.production_profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 24%;
}

/* dropdown */
@media (max-width: 609px) {
  .to-date {
    margin-left: 23px !important;
  }
}

@media (max-width: 506px) {
  .Dates {
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 1500px) {
  .home {
    padding: 7px;
    width: 30px;
  }

  .page-name {
    color: white;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0px !important;
    text-transform: capitalize;
    font-family: "Nunito-regular-400";
  }

  .icons {
    font-size: 34px;
  }

  .user {
    padding: 5px;
    width: 30px;
  }
}

/* .page-name {
  color: white;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
} */

@media (max-width: 550px) {
  .controllers {
    display: flex;
    justify-content: flex-end;
    gap: 7px;
    position: fixed;
    bottom: 8px;
    right: 24px;
    flex-flow: wra;
  }

  .motor {
    outline: none;
    border: none;
    color: white;
    font-family: "Nunito";
    background: #06567b;
    border-radius: 8px;
    padding: 12px 11px;
    float: right;
    font-weight: 600;
    font-size: 16px;
  }

  .light {
    outline: none;
    border: none;
    color: rgb(48, 44, 44);
    font-family: "Nunito";
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 12px 11px;
    float: right;
    font-weight: 600;
    font-size: 16px;
  }
}

@media (max-width: 999px) {
  .top-nav {
    display: none;
  }
}

@media (min-width: 990px) and (max-width: 1000px) {
  .icons {
    display: none;
  }
}

/* @media (max-width: 1000px) {
  .top-nav {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 3px 8px;
    border: none;
    outline: none;
    padding: 0px 0px;
  }
} */
.canvasbtn {
  background-color: #06567b;
  color: white;
  font-size: 23px;
  padding: 0px 11px !important;
  width: 42px;
  height: 36px;
}

@media (max-width: 1000px) {
  .side-bar {
    display: none;
  }

  .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    transform: none;
    display: none;
  }
}

@media (max-width: 1000px) {
  .dashbord-pages {
    margin: 0px;
  }

  .navbar {
    background: linear-gradient(167.76deg,
        #9cd4ec -32.26%,
        #2288b8 36.24%,
        #06567c 109.17%) !important;
  }

  .navbar-toggler {
    background-color: white !important;
  }
}

.page-name-nav {
  color: white;
  margin-bottom: 0px;
}

.user_dropdown {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.user_dropdown_logout {
  display: flex;
  align-items: center;
  gap: 5px;
}

.menuBox {
  margin-left: 10px;
}