body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.back_to_home_btn {
  float: right;
  color: white !important;
  background: #01a0e2;
  border-radius: 7px;
  height: 39px;
  width: 97px;
  text-align: center;
  font-weight: 600 !important;
  margin-left: 10px;
  border: none;
}

.pointerCurser {
  cursor: pointer;
}

.redBox .form-check-input {
  border: 2px solid #c60000;
}

.ant-select-selector:hover,
.ant-select-selector:focus,
.ant-select-selector:active {
  border: 0.5px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%) !important;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-3px);
  }

  50% {
    transform: translateX(3px);
  }

  75% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}
.ant-select-selection-item {
  color: #000 !important;
}

.shake {
  animation: shake 0.5s;
}

.searchInputClass {
  border: none !important;
  width: 150px;
}

.searchInputClass .ant-select-selector,
.searchInputClass .ant-select-selector:hover,
.searchInputClass .ant-select-selector:active,
.searchInputClass .ant-select-selector:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.CheckBox_innerStyle .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff8b00 !important;
  border-color: #ff8b00 !important;
}

.CheckBox_innerStyle .ant-checkbox-inner {
  border-color: #0b2930 !important;
}

.SwitchStyles .ant-switch {
  background-color: red !important;
}

.SwitchStyles .ant-switch-checked .ant-switch-inner {
  background-color: green !important;
}

.errorText {
  font-size: 14px;
  margin-bottom: 0px;
  color: red;
  font-family: "Nunito-semibold-600";
  margin-top: 5px;
}

.inlineSelect {
  box-shadow: none !important;
  max-width: 110px !important;
  height: 25px !important;
}

.inlineSelect .ant-select-selector {
  border-radius: 10px;
  box-shadow: none !important;
  background: #b2e0ff !important;
  font-size: 13px !important;
  border: 1px solid #a3a0ff8f !important;
}

.inlinefield {
  border-radius: 10px !important;
  box-shadow: none !important;
  background: #b2e0ff !important;
  border: 1px solid #a3a0ff8f !important;
  font-size: 13px !important;
  max-width: 100% !important;
  height: 50px !important;
  color: #000 !important;
  padding: 5px 10px !important;
}

.confirmationmodal .modal-content {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  border: none !important;
}

.segmentedStyle .ant-segmented-group {
  gap: 5px !important;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 15px;
  /* Width of the scrollbar */
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track color */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Handle color */
  border-radius: 10px;
  /* Rounded handle */
  border: 3px solid #f1f1f1;
  /* Space around the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker color when hovered */
}

.ant-table-thead > tr > th {
  background-color: #ffd8d8 !important;
  /* Yellow */
  color: #000 !important;
  /* White text */
  font-weight: bold;
}

/* Ensure it applies to the sticky header */
.ant-table-header {
  background-color: #ffd8d8 !important;
  position: sticky;
  top: 65px;
  z-index: 10;
}

.ant-table-row-selected > .ant-table-cell {
  background-color: #fffbe1 !important;
}

.valveSettingTable thead th {
  background-color: #ffd8d8 !important;
}
.valveSettingTable table > thead > tr > th {
  padding: 10px 10px;
  font-size: 16px;
}
.valveSettingTable table > tbody > tr > td {
  padding: 10px 10px;
  font-size: 16px;
}

.valveSettingTable thead th:last-child {
  border-top-right-radius: 10px;
}
.valveSettingTable thead th:first-child {
  border-top-left-radius: 10px;
}

.timeInputBox .rc-time-picker-input {
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%) !important;
  border-radius: 8px !important;
  width: 210px !important;
  height: 51px !important;
  outline: none !important;
  outline: none !important;
  font-family: "Nunito-semibold-600";
  font-size: 14px;
}
@media (max-width: 1300px) {
  .valveSettingTable table > thead > tr > th {
    font-size: 14px;
  }
  .valveSettingTable table > tbody > tr > td {
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  .responsiveStyels {
    /* width: 100px !important; */
    min-width: 100px !important;
    width: 120px !important;
    max-width: 120px !important;
  }
}

.NoWrapStyle {
  flex-flow: nowrap !important;
  align-items: flex-start !important;
}

.filedPickBackground {
  background-color: #ffd8d8 !important;
}
