@font-face {
  font-family: "Nunito-bold-700";
  src: url(../../Assets/Fonts/Nunito-Bold.ttf);
}
@font-face {
  font-family: "Nunito-semibold-600";
  src: url(../../Assets/Fonts/Nunito-SemiBold.ttf);
}
@font-face {
  font-family: "Nunito-regular-400";
  src: url(../../Assets/Fonts/Nunito-Regular.ttf);
}

.container-div {
  display: flex;
  align-items: center;
  font-family: "poppins";
  font-size: 1rem;
  width: 1300px;
  margin: auto;
  font-weight: 600;
  justify-content: space-between;
  padding: 0;
}

.container-body {
  height: fit-content;
  width: 70%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 60px;
}

.head {
  color: #000000;
  font-family: "Nunito-semibold-600";
  font-weight: bolder;
  font-size: 27px;
  cursor: default;
  /* margin-top: 5px; */
}

.searchfield {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 6px 5px 15px;
}
.search_field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 15px 0;
  font-family: "Nunito-bold-700";
}
.search_field ::-webkit-input-placeholder {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500px;
  color: gray;
  font-family: "poppins";
}
.searchfield ::-webkit-input-placeholder {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500px;
  color: gray;
  font-family: "poppins";
}
.dashbord-details {
  width: 200px;
  padding: 20px 35px;
  height: 159px;
  /* height: 20%; */
  font-family: "Nunito-semibold-600";

  outline: none;
  border: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 20px;
  /* font-size: 18px; */
  /* font-weight: 600; */
  font-family: "Nunito";
  text-align: left;
  text-transform: capitalize;
  font-weight: 900 !important;
  font-size: 28px;
  line-height: 16px;
}
.dashbord_details {
  padding: 20px 35px;
  height: 160px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 20px;
  text-align: left;
  text-transform: capitalize;
  font-size: 28px;
  line-height: 16px;
  font-family: "Nunito-bold-700";
}
.dashbord-details-total {
  background: #e8eaff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  /* font-size: 18px; */
  /* font-weight: 600; */
  /* padding: 20px 35px; */
  color: #5f5e9a;
}
.dashbord-details-on {
  background: #eaf7ee;
  color: #46875d;
}
.dashbord-details-off {
  background: #fee8eb;
  color: #b46a77;
}
.dashbord-details-load {
  background: #e8cef1;
  color: #3e0856;
}
.dashbord-details-group {
  background: #ffeacf;
  color: #f8b155;
}
.dashbord-details-notification {
  background: #e0e0e0;
  color: #5e5c67;
}

.dashboard-btn {
  display: flex;
  flex-flow: wrap;
  gap: 38px 40px;
  /* margin: 19px 0px; */
}
.dashboard_btn {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
@media (max-width: 1330px) {
  .dashboard_btn {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1120px) {
  .dashboard_btn {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 900px) {
  .dashboard_btn {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 680px) {
  .dashboard_btn {
    grid-template-columns: repeat(2, 1fr);
  }
}
.count {
  color: #000000a6;
  font-weight: 900;
  font-family: "Nunito-bold-700";
  font-size: 16px;
}
.dash-label {
  color: white;
  font-size: 1.3rem;
  font-weight: 400;
}
.flex-end {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end !important;
  margin-left: auto;
  flex-wrap: wrap;
}
.dash-input {
  outline: none;
  cursor: pointer;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 8%);
  border-radius: 8px;
  width: 171px;
  padding: 10px 20px;
  font-family: "Nunitos";
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  appearance: none;
  background-position: 90%;
  background-repeat: no-repeat;
  background-image: url(../image/down-arrow.png);
}
.background-select {
  color: rgba(0, 0, 0, 0.7);
  appearance: none;
  background-position: 90%;
  background-repeat: no-repeat;
  background-image: url(../image/down-arrow.png);
}
.controller-list {
  display: flex;
}
.light-img {
  font-size: 1.8rem;
  color: black;
}
.darklight-img {
  font-size: 1.8rem;
  color: white;
}
.controllerCard {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
/* Consumption Card Block */
.consumptionCard {
  background-color: #e5f4ff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border: 1px solid #d6edff;
  padding: 20px 25px;
}
.consumptionCard h3 {
  font-size: 20px;
  margin-bottom: 5px;
  font-family: "Nunito-bold-700";
}
.consumptionContant {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: space-between;
  flex-flow: wrap;
}
.consumptionIcon {
  width: 50px;
}
.consumptionContainer {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  flex-flow: wrap;
}
.consumptionFilterBlock {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.consumptionFilterBlock label {
  font-size: 15px;
  font-family: "Nunito-semibold-600";
}

/* end */

/* @media (min-width: 700px) and (max-width: 1300px) {
  .dashboard-btn {
    justify-content: space-between;
    gap: 9px 7px;
  }
  .dashbord-details {
    width: 156px;
    padding: 20px 35px;
    height: 136px;
  }
} */
@media (min-width: 700px) and (max-width: 800px) {
  .dashbord-details {
    width: 181px;
    height: 136px;
  }
  .dashboard-btn {
    gap: 9px 7px;
  }
}
@media (min-width: 800px) and (max-width: 900px) {
  .dashbord-details {
    width: 181px;
    height: 136px;
  }
  .dashboard-btn {
    gap: 9px 7px;
  }
}
@media (min-width: 900px) and (max-width: 1000px) {
  .dashbord-details {
    width: 162px;
    height: 136px;
  }
  .dashboard-btn {
    gap: 9px 7px;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .dashbord-details {
    width: 150px;
    height: 136px;
  }
  .dashboard-btn {
    gap: 9px 7px;
    justify-content: space-evenly;
  }
}
@media (min-width: 1100px) and (max-width: 1200px) {
  .dashbord-details {
    width: 170px;
    height: 136px;
  }
  .dashboard-btn {
    gap: 9px 7px;
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .dashbord-details {
    width: 186px;
    height: 136px;
  }
  .dashboard-btn {
    gap: 9px 7px;
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .dashbord-details {
    width: 184px;
  }
}

@media (max-width: 989px) {
  .top-bar > .page-container {
    display: none;
  }
  .side-bar {
    display: none;
  }
  .top-bar {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .flex-end {
    gap: 12px;
    align-items: center;
    justify-content: center !important;
  }
}

.addbutton {
  border: none;
  background: #5c9cb1;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Nunito-semibold-600";
  border-radius: 6px;
  padding: 7px 13px;
  font-size: 22px;
}
.consumptionbtn {
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  color: white;
  padding: 1px 20px;
  background: #53a2fc;
  font-size: 16px;
  height: 40px;
  font-family: "Nunito-semibold-600";
}
@media (min-width: 1000px) {
  .top-bar > .navbar {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
    gap: 0 25px;
    justify-content: space-between !important;
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  /* .count {
    color: #000000a6;
    font-weight: 900;
    font-family: "Nunito-bold-700";
    font-size: 15px;
  } */
  .from-to-date {
    display: flex;
    align-items: baseline;
    gap: 13px;
  }
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .dashbord-details {
    width: 198px;
    height: 137px;
  }
  .dashboard-btn {
    justify-content: space-between;
    display: flex;
    flex-flow: wrap;
    gap: 0px;
  }
}
@media (min-width: 1920px) {
  .dashbord-details {
    width: 276px;
  }
  .dashboard-btn {
    justify-content: space-evenly;
    display: flex;
    flex-flow: wrap;
    /* gap: 17px 43px; */
  }
}
@media (max-width: 800px) {
  .Dates {
    display: flex;
    flex-wrap: wrap;
    gap: 17px 11px !important;
  }
  .from-to-date {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }

  .layout-btn {
    padding: 7px 17px !important;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .dashbord-details {
    width: 220px;
  }
  .dashboard-btn {
    justify-content: space-between;
    gap: 0px;
  }
}
@media (min-width: 1750px) and (max-width: 1920px) {
  .dashboard-btn {
    display: flex;
    flex-flow: wrap;
    gap: 0px;
    justify-content: space-between;
  }
  .dashbord-details {
    width: 270px;
  }
}
@media (min-width: 1601px) and (max-width: 1750px) {
  .dashboard-btn {
    display: flex;
    flex-flow: wrap;
    gap: 0px;
    justify-content: space-between;
  }
  .dashbord-details {
    width: 247px;
  }
}
@media (min-width: 600px) and (max-width: 700px) {
  .dashboard-btn {
    justify-content: space-evenly !important;
    gap: 11px 9px;
  }
  .dashbord-details {
    width: 178px;
  }
}
@media (min-width: 550px) and (max-width: 600px) {
  .dashboard-btn {
    justify-content: space-evenly !important;
    gap: 11px 9px;
  }
  .dashbord-details {
    width: 161px;
    height: 135px;
  }
}
@media (min-width: 500px) and (max-width: 550px) {
  .dashboard-btn {
    justify-content: space-evenly !important;
    gap: 11px 9px;
  }
  .dashbord-details {
    width: 223px;
    height: 110px;
  }
}
@media (min-width: 470px) and (max-width: 500px) {
  .dashboard-btn {
    justify-content: space-evenly !important;
    gap: 11px 9px;
  }
  .dashbord-details {
    width: 209px;
    height: 110px;
  }
}
